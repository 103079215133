import React from "react";
import toast from "react-hot-toast";
import { IoShare } from "react-icons/io5";
import { trackButtonClick, trackSuccessfulAction } from "../Constants/mixpanel";

export default function ShareList() {
  const isMobile = () => {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  };

  const shareList = () => {
    trackButtonClick("Share list");

    if (isMobile()) {
      const shareData = {
        title: "Share my Album Listen List",
        text: window.location.href,
        url: window.location.href,
      };

      navigator
        .share(shareData)
        .then(() => {
          trackSuccessfulAction("Shared list");
          toast.success("Successfully shared list");
        })
        .catch((e) => {
          if (e.name === "AbortError") {
            trackSuccessfulAction("Exited share popup without sharing");
          }
        });
    } else {
      navigator.clipboard.writeText(window.location.href);
      trackSuccessfulAction("Copied list link to clipboard");
      toast.success("Copied list link to clipboard");
    }
  };

  return (
    <>
      <IoShare
        title={"share"}
        className="h-7 w-7 mr-5 text-text-base active:text-text-active"
        onClick={shareList}
      />
    </>
  );
}
