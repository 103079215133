import * as Sentry from "@sentry/react";
import { onAuthStateChanged } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Switch, useHistory } from "react-router";
import { getRefreshToken } from "./Constants/helpers";
import CallbackPage from "./Pages/CallbackPage";
import LandingPage from "./Pages/LandingPage";
import ListPage from "./Pages/ListPage";
import ProfilePage from "./Pages/ProfilePage";
import SearchPage from "./Pages/SearchPage";
import SplashPage from "./Pages/SplashPage";
import { auth } from "./firebase";

function App() {
  const history = useHistory();

  mixpanel.init("1d9d13675e474d734ccb178c721836ea", {
    track_pageview: true,
    persistence: "localStorage",
  });

  Sentry.init({
    dsn: "https://ea7a11b32535a194717b7ea8378efecb@o4507474674319360.ingest.us.sentry.io/4507474676219904",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "https://albumlistenlist.com/",
      "https://album-listen-list-api.fly.dev/",
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Handle Google Auth
  onAuthStateChanged(auth, (user) => {
    if (user) {
      mixpanel.identify(user.uid);
    } else {
      mixpanel.identify();
    }
  });

  // Handle Spotify Auth
  useEffect(() => {
    const spotifyRefreshToken = localStorage.getItem("spotify_refresh_token");

    if (!spotifyRefreshToken || spotifyRefreshToken === "undefined") {
      history.push("/");
      return;
    }

    getRefreshToken(spotifyRefreshToken).then((t) => {
      localStorage.setItem("spotify_token", t.spotify_token);
      localStorage.setItem("spotify_refresh_token", t.spotify_refresh_token);
    });
  }, [history]);

  return (
    <main className="text-text-base bg-background-base">
      <Switch>
        <Route path="/" component={SplashPage} exact />
        <Route path="/landing" component={LandingPage} exact />
        <Route path="/callback" component={CallbackPage} exact />
        <Route path="/list/:uid" component={ListPage} exact />
        <Route path="/search" component={SearchPage} exact />
        <Route path="/profile" component={ProfilePage} exact />
      </Switch>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: "#444444",
            color: "#ffffff",
          },
        }}
      />
    </main>
  );
}

export default App;
