import axios from "axios";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { backendBaseUrl } from "./constants";

export const persistValue = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const persistAlbum = (album: any) => {
  setDoc(
    doc(firestore, `users/${auth.currentUser!.uid}/listenList/${album.id}`),
    {
      name: album.name,
      artist: album.artists[0].name,
      spotify_url: album.external_urls.spotify,
      api_url: album.href,
      images: album.images,
      release_date: album.release_date,
      total_tracks: album.total_tracks,
      time_added: new Date().toISOString(),
    },
  );
};

export const persistAlbums = (albums: Album[]) => {
  persistValue("albums", JSON.stringify(albums));
};

export const fetchValue = (key: string, fallback: string): string => {
  return localStorage.getItem(key) || fallback;
};

export const fetchAlbums = (): Album[] => {
  return JSON.parse(fetchValue("albums", "null")) || [];
};

export const deleteAlbum = async (
  albumId: string,
  albums: Album[],
  setAlbums: React.Dispatch<React.SetStateAction<Album[]>>,
) => {
  deleteDoc(
    doc(firestore, `users/${auth.currentUser?.uid}/listenList`, albumId),
  );
  const newAlbums = albums.filter((a: any) => a.id !== albumId);
  persistValue("albums", JSON.stringify(newAlbums));
  setAlbums(newAlbums);
};

export const getOS = () => {
  if (/android/i.test(navigator.userAgent)) {
    return "Android";
  } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return "iOS";
  } else {
    return "Desktop";
  }
};

export const sortAlbums = (
  albums: Album[],
  field: SortByOptions,
  direction: string,
) => {
  return [...albums].sort((album1, album2) => {
    const a = album1[field]?.toString() || "";
    const b = album2[field]?.toString() || "";

    return direction === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  });
};

export const getDirection = (
  newValue: SortByOptions,
  sortValue: SortByOptions,
  sortDirection: "asc" | "desc",
) => {
  if (newValue === sortValue) {
    return sortDirection === "asc" ? "desc" : "asc";
  } else if (newValue === "time_added" || newValue === "release_date") {
    return "desc";
  } else {
    return "asc";
  }
};

export const searchAlbums = (searchText: string, albums: Album[]) => {
  return albums.filter(
    (a: any) =>
      a.name.toLowerCase().includes(searchText.toLowerCase()) ||
      a.artist.toLowerCase().includes(searchText.toLowerCase()),
  );
};

export const filterAlbums = (filterOption: FilterType, albums: Album[]) => {
  if (filterOption === "all") {
    return albums;
  } else if (filterOption === "not-reviewed") {
    return albums.filter((a: any) => !a.rating);
  } else if (filterOption === "reviewed") {
    return albums.filter((a: any) => a.rating);
  } else {
    return [];
  }
};

export const showModal = (modalId: string) => {
  (document.getElementById(modalId) as HTMLFormElement).showModal();
};

export const getRefreshToken = async (spotifyRefreshToken: string) => {
  const res = await axios.get(`${backendBaseUrl}/refresh_token`, {
    params: { refresh_token: spotifyRefreshToken },
  });
  return res.data;
};
