import axios from "axios";
import { signOut } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { BsSpotify } from "react-icons/bs";
import {
  IoHeart,
  IoLogOutOutline,
  IoMail,
  IoTrashOutline,
} from "react-icons/io5";
import { useHistory } from "react-router";
import DeleteAccountModal from "../Components/DeleteAccountModal";
import FeedbackModal from "../Components/FeedbackModal";
import { showModal } from "../Constants/helpers";
import { trackButtonClick, trackModalOpen } from "../Constants/mixpanel";
import Page from "../Containers/Page";
import { auth } from "../firebase";

export default function ProfilePage() {
  const [me, setMe] = useState<any>(null);
  const history = useHistory();
  const iconStyle =
    "mr-4 h-5 w-5 text-text-subdued group-active:text-text-active";
  const textStyle = "text-text-base group-active:text-text-active";

  useEffect(() => {
    const localProfile = JSON.parse(localStorage.getItem("profile") || "null");
    setMe(localProfile);

    getMe().then((m) => {
      localStorage.setItem("profile", JSON.stringify(m));
      setMe(m);
    });
  }, []);

  const getMe = async () => {
    const res = await axios.get("https://api.spotify.com/v1/me", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("spotify_token"),
      },
    });
    return res.data;
  };

  const logout = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      history.push("/");
    });
  };

  const ProfileButton = ({ children, onClick }: any) => {
    return (
      <button
        onClick={onClick}
        className="flex items-center w-full rounded-full ml-4 mb-6 group"
      >
        {children}
      </button>
    );
  };

  return (
    <Page>
      <div className="flex justify-between mb-12">
        <p className="text-2xl text-text-base w-full">Profile</p>
      </div>
      <div className="flex flex-col items-center mb-12">
        <img
          src={me?.images[1].url}
          alt=""
          className="object-cover h-40 w-40 mb-6 rounded"
        />
        <p className="text-sm text-text-base">{me ? me.display_name : ""}</p>
      </div>
      <ProfileButton
        onClick={() => {
          trackButtonClick("Go to Spotify profile");
          window.location.href = me?.external_urls.spotify;
        }}
      >
        <BsSpotify className={iconStyle} />
        <p className={textStyle}>Spotify</p>
      </ProfileButton>
      <ProfileButton
        onClick={() => {
          trackButtonClick("Send feedback");
          showModal("feedback-modal");
        }}
      >
        <IoMail className={iconStyle} />
        <p className={textStyle}>Feedback</p>
      </ProfileButton>
      <ProfileButton
        onClick={() => {
          trackButtonClick("Support Me");
          window.open("https://www.buymeacoffee.com/joshuarichards");
        }}
      >
        <IoHeart className={iconStyle} />
        <p className={textStyle}>Support Me</p>
      </ProfileButton>
      <ProfileButton
        onClick={() => {
          trackButtonClick("Logout user");
          mixpanel.reset();
          logout();
        }}
      >
        <IoLogOutOutline className={iconStyle} />
        <p className={textStyle}>Logout</p>
      </ProfileButton>
      <button
        className="flex w-full rounded-full ml-4 mb-4 group"
        onClick={() => {
          showModal("delete-modal");
          trackModalOpen("Delete Account");
        }}
      >
        <IoTrashOutline className={iconStyle} />
        <p className={textStyle}>Delete Account</p>
      </button>
      <DeleteAccountModal />
      <FeedbackModal />
    </Page>
  );
}
