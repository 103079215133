import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Modal from "../Containers/Modal";
import { firestore } from "../firebase";

export default function FeedbackModal() {
  const [email, setEmail] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  const onSubmit = () => {
    setDoc(doc(firestore, `feedback/${new Date().toISOString()}`), {
      email: "test@gmail.com",

      feedback: feedbackText,
      time_added: new Date().toISOString(),
    });
    setFeedbackText("");
    toast.success("Feedback submitted. Thanks for helping me improve!");
  };

  return (
    <Modal modalId="feedback-modal" size="large">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl text-text-base">Send Me Feedback</h2>
        <p className="text-sm text-text-subdued">
          Notice something isn't working correctly or got an idea for how I
          could make Album Listen List better? Type it in below and I'll get
          back to you ASAP!
        </p>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email e.g. john@gmail.com (optional)"
          className="input input-sm bg-background-base placeholder:text-neutral-600"
        />
        <textarea
          className="textarea textarea-sm placeholder:text-neutral-600 bg-background-base h-28"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="Your feedback or idea here!"
        ></textarea>
        <form method="dialog">
          <button className="btn bg-background-base text-spotify-green" disabled={feedbackText === ""} onClick={onSubmit}>
            Submit Feedback
          </button>
        </form>
      </div>
    </Modal>
  );
}
