import React, { useEffect, useState } from "react";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
import {
  fetchValue,
  getDirection,
  persistAlbums,
  persistValue,
  sortAlbums,
} from "../Constants/helpers";
import { trackButtonClick } from "../Constants/mixpanel";
import Modal from "../Containers/Modal";

type Props = {
  albums: Album[];
  setAlbums: React.Dispatch<React.SetStateAction<Album[]>>;
};

export default function OptionsModal({ albums, setAlbums }: Props) {
  const [sortValue, setSortValue] = useState<SortByOptions>("time_added");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    const directionValue = fetchValue("sortDirection", "desc");
    const direction = directionValue === "asc" ? "asc" : "desc";
    setSortDirection(direction);
    setSortValue(fetchValue("sortValue", "time_added") as SortByOptions);
  }, []);

  const sortBy = (v: SortByOptions) => {
    const d = getDirection(v, sortValue, sortDirection);
    setSortValue(v);
    setSortDirection(d);
    persistValue("sortValue", v);
    persistValue("sortDirection", d);

    trackButtonClick("Sort option selected", {
      direction: d,
      sortValue: v,
    });

    const sortedAlbums = sortAlbums(albums, v, d);
    persistAlbums(sortedAlbums);
    setAlbums(sortedAlbums);
  };

  const SortButton = ({ sortButtonValue, sortButtonText }: any) => {
    return (
      <div
        className={`mb-3 flex justify-between cursor-pointer ${
          sortValue === sortButtonValue
            ? "text-spotify-green active:text-spotify-green-active"
            : "text-text-base active:text-text-active"
        }`}
        onClick={() => sortBy(sortButtonValue)}
      >
        <p className="text-sm">{sortButtonText}</p>
        {sortValue === sortButtonValue ? (
          sortDirection === "asc" ? (
            <IoArrowUp className="h-4 w-4" />
          ) : (
            <IoArrowDown className="h-4 w-4" />
          )
        ) : (
          <div className="h-4 w-4" />
        )}
      </div>
    );
  };

  return (
    <Modal modalId="modal-options">
      <p className="text-xs mb-6 text-text-subdued">Sort By</p>
      <SortButton
        sortButtonValue="time_added"
        sortButtonText="Recently Added"
      />
      <SortButton sortButtonValue="name" sortButtonText="Album Name" />
      <SortButton sortButtonValue="artist" sortButtonText="Artist Name" />
      <SortButton
        sortButtonValue="release_date"
        sortButtonText="Release Date"
      />
      {/* <SortButton sortButtonValue="rating" sortButtonText="Rating" /> */}
    </Modal>
  );
}
